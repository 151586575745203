exports.components = {
  "component---src-node-blog-tsx": () => import("./../../../src/node/blog.tsx" /* webpackChunkName: "component---src-node-blog-tsx" */),
  "component---src-node-category-tsx": () => import("./../../../src/node/category.tsx" /* webpackChunkName: "component---src-node-category-tsx" */),
  "component---src-node-machine-tsx": () => import("./../../../src/node/machine.tsx" /* webpackChunkName: "component---src-node-machine-tsx" */),
  "component---src-node-page-tsx": () => import("./../../../src/node/page.tsx" /* webpackChunkName: "component---src-node-page-tsx" */),
  "component---src-node-tag-tsx": () => import("./../../../src/node/tag.tsx" /* webpackChunkName: "component---src-node-tag-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

